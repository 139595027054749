import React from "react";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import {
  Button,
  CardActionArea,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import Campaign from "../pages/Campaigns"
import ExpandMore from "@material-ui/icons/ExpandMore";

interface Props {
  content: {
    id: number;
    uuid: string;
    campaign_name: string;
    created_at: string;
    startDate: string;
    endDate: string;
    profiles: [
      {
        id: string;
        uuid: string;
        name: string;
        position: string;
        location: string;
      }
    ];
  };
}

function CampaignCard(props: Props): JSX.Element {
  const useStyles = makeStyles({
    card: {
      boxShadow: "0px 0px 10px #1C242C1A",
      padding: "16px",
      paddingBottom: "16px",
    },
    cardText: {
      textAlign: "left",
      marginLeft: "10px",
    },
    viewButton: { textTransform: "none", marginLeft: "10px" },
    campaignButton: { textTransform: "none" },
    profileAvatar: { width: "40px", height: "40px" },
    avatarTheme: { backgroundColor: "#4CA9B2" },
  });

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <CardActionArea
      component={RouterLink}
      to={{
        pathname: `/campaign/${props.content.uuid}`
      }}
    >
      <Card className={classes.card}>
        <CardContent>
          <Grid >
            <Grid item>
              <Grid
                container
                direction="column"
                spacing={1}
                alignItems="flex-start"
              >
                <Grid item>
                  <Typography>
                    <b>{props.content["campaign_name"]}</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {props.content["created_at"].substring(0, 10)}
                  </Typography>
                </Grid>
                <Grid item>

                  {/* Avatars displayed as unique users */}
                  <AvatarGroup>
                    {props?.content?.profiles?.map((profiles) => (
                      <Avatar
                        className={classes.profileAvatar}
                        src={`https://ui-avatars.com/api/?name=${profiles.position}&color=FFFF&background=4CA9B2`}
                        component={RouterLink}
                        to={{
                          pathname: `/profile/${profiles.uuid}`
                        }}
                        key={profiles.uuid}
                      >
                        {profiles.name.charAt(0)}
                      </Avatar>
                    ))}

                    
                  </AvatarGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </CardActionArea>
  );
}

export default CampaignCard;
