import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import CardDisplay from "../../components/ProfileCardDisplay";
import { useState } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import logo from "../../images/Nodero-Logo-White.png";
import { useParams } from "react-router-dom";
import ShareIcon from "@material-ui/icons/Share";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import LinkIcon from "@material-ui/icons/Link";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {
  Dialog,
  DialogActions,
  TextField,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Grid,
} from "@material-ui/core";
interface Campaign {
  campaign: {
    id: number;
    campaign_name: string;
    campaign_details: string;
    profiles: [
      {
        id: string;
        name: string;
        position: string;
        location: string;
      }
    ];
  };
}
interface ProfileCardData {
  profiles: {
    id: number;
    uuid: string;
    name: string;
    position: string;
    skills: [{
      name: any;
      level: any;
      keywords: [any];
    }];
    location: string;
    campaigns: [
      {
        id: number;
        campaign_name: string;
        created_at: string;
        startDate: string;
        endDate: string;
      }
    ];
  }[];
}
interface Profiles {
  profiles: {
    id: number;
    name: string;
    position: string;
    skills: [{
      name: any;
      level: any;
      keywords: [any];
    }];
    location: string;
  }[];
}

function CampaignShared(): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [copied, setCopied] = useState("");
  const [emailRecepient, setEmailRecepient] = React.useState<string>("");
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [profiles, setProfiles] = React.useState<ProfileCardData["profiles"]>();
  const [url, setUrl] = React.useState<string>("");
  const [campaign, setCampaign] = React.useState<Campaign["campaign"]>();
  const [filteredData, setFilteredData] =
    React.useState<ProfileCardData["profiles"]>();

  let urlParams: any = useParams();
  const campaignID = urlParams.uuid
  console.log("CampaignShared", campaignID)
  const shareLink = window.location.origin + "/share/campaign/" + campaignID

  const page: string = "campaigns";

  const useStyles = makeStyles({
    floatRight: {
      marginBottom: ".5vh",
      marginLeft: "auto",
      textTransform: "none",
    },
    Button: { textTransform: "none", marginBottom: ".5vh" },
    appBar: {
      height: "56px",
      display: "flex",
      alignContent: "center",
    },
    logo: {
      maxWidth: "300px",
      height: "auto",
      marginBottom: "20px",
    },
    campaignContainer: {
      textAlign: "center",
      marginTop: "100px",
    },
    summary: {
      maxWidth: "600px",
      margin: "auto",
    },
    cardGrid: {
      margin: "auto",
      marginTop: "40px",
    },
    noProfileMessage: {
      margin: "auto",
    },
    otherlogo: {
      width: "150px",
      marginBottom: "0.5vh"
    }, shareModal: {},
    shareAvatar: { width: "35px", height: "35px", background: "#4CA9B2" },
    shareTitle: { marginLeft: "8px" },
    shareTitleGroup: { marginTop: '5px' },
    copyLinkModal: { marginTop: '50%' },
    emailRecipient: {
      backgroundColor: "#EDEEF0",
      borderRadius: "4px",
      marginTop: "10px",
      color: "#4CA9B2",
      "& label.Mui-focused": {
        color: "#4CA9B2",
      },
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#4CA9B2",
      },
      "& .MuiFilledInput-root": {
        background: "#EDEEF0",
      },
    }, dialogActions: {
      marginRight: "15px",
      marginBottom: "12px",
    },
  });
  const classes = useStyles();


  //getting campaign data
  useEffect(() => {
    const getCampaign = async () => {
      const campaignData = await fetchCampaign();
      setCampaign(campaignData);
    };
    getCampaign();
    setLoading(false);
  }, []);

  //fetching location state id in order to return profile
  const fetchCampaign = async () => {
    const res = await fetch(process.env.REACT_APP_API_URL + "/campaigns/" + campaignID);

    const data = await res.json();

    return data;
  };

  //get profile cards display
  useEffect(() => {
    const getProfiles = async () => {
      const profileData = await fetchProfiles();
      setFilteredData(profileData);
    };
    getProfiles();
    setLoading2(false);
  }, []);

  const fetchProfiles = async () => {
    const res = await fetch(process.env.REACT_APP_API_URL + "/campaigns/" + campaignID + "/profiles");
    const data = await res.json();
    console.log(data)
    return data;
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCopied("");
    setEmailRecepient("");
  };

  const handleShare = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: 'include' as RequestCredentials,
      body: JSON.stringify({
        toAddress: emailRecepient
      }),
    };
    fetch(process.env.REACT_APP_API_URL + "/campaigns/" + campaignID + "/share", requestOptions);
    setOpen(false);
  };

  async function copy() {
    await navigator.clipboard.writeText(shareLink);
    setCopied("Copied");
  }
  return (
    <Container maxWidth="xl" className={classes.campaignContainer}>
      {loading === true ? (
        <Typography>Loading Campaign...</Typography>
      ) : (
        <div>
          <AppBar className={classes.appBar} position="fixed" color="inherit">
            <Toolbar>
              <img src="/nodero_logo_horizontal (white background).svg" className={classes.otherlogo} />
              <Button
                className={classes.floatRight}
                href={url}
                disableRipple
              >
                <GetAppIcon color="secondary" />
                <Typography style={{ marginLeft: "5px" }}>Download</Typography>
              </Button>
              <Button
                disableRipple
                className={classes.Button}
                onClick={handleClickOpen}
              >
                <ShareIcon color="secondary" />
                <Typography style={{ marginLeft: "5px" }}>Share</Typography>
              </Button>

              <Dialog
                className={classes.shareModal}
                open={open}
                onClose={handleClose}
              >
                <DialogTitle>
                  <Grid
                    className={classes.shareTitleGroup}
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                  >
                    <Avatar className={classes.shareAvatar} color="primary">
                      <PersonAddIcon />
                    </Avatar>
                    <Typography className={classes.shareTitle}>
                      Share with people and groups
                    </Typography>
                  </Grid>
                </DialogTitle>

                <DialogContent>
                  <DialogContentText>
                    Share this resume by copying the link below or sending an email
                  </DialogContentText>

                  <TextField
                    onKeyPress={(e) => e.key === "Enter" && handleShare()}
                    className={classes.emailRecipient}
                    value={emailRecepient}
                    onChange={(e) => setEmailRecepient(e.target.value)}
                    color="primary"
                    margin="dense"
                    id="name"
                    label="Email Address"
                    type="email"
                    fullWidth
                    size="small"
                    variant="filled"
                  />
                </DialogContent>

                <DialogActions className={classes.dialogActions}>
                  <Button className={classes.Button} onClick={handleClose}>
                    Cancel
                  </Button>

                  <Button
                    className={classes.Button}
                    variant="outlined"
                    color="secondary"
                    onClick={copy}
                  >
                    {!copied ? "Copy Link" : "Copied!"}
                  </Button>

                  <Button
                    className={classes.Button}
                    type="submit"
                    onClick={handleShare}
                    variant="contained"
                    color="secondary"
                  >
                    Share
                  </Button>
                </DialogActions>
              </Dialog>
            </Toolbar>
          </AppBar>
          <div>
            <img src={logo} alt="logo" className={classes.logo} />
          </div>
          {campaign ? (
            <div>
              <Typography variant="h6" color="secondary">
                <b>{campaign.campaign_name}</b>
              </Typography>
              <Typography className={classes.summary}>
                {campaign.campaign_details}
              </Typography>
            </div>
          ) : (
            <Typography>No Campaign</Typography>
          )}
          <Grid className={classes.cardGrid}>
            {loading2 === true ? (
              <Typography>Loading Profiles...</Typography>
            ) : (
              <div>
                {filteredData ? (
                  <CardDisplay listData={filteredData} page={page} campaign={"/share/campaign/" + campaignID + "/"} />
                ) : (
                  //Display Message when no profiles are assigned to a campaign
                  <Typography className={classes.noProfileMessage}>
                    There are no profiles assigned to this campaign
                  </Typography>
                )}
              </div>
            )}
          </Grid>
        </div>
      )}
    </Container>
  );
}

export default CampaignShared;

// Header – back to campaigns button, edit, download, share
// Campany logo
// Title, and details
// Grid of participants
