import HeaderProfile from "../../components/HeaderProfile";
import { makeStyles } from "@material-ui/styles";
import { useState, useEffect } from "react";
import { AppBar, Box, Button, Toolbar, Typography } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useHistory, useParams } from "react-router-dom";
import React from "react";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ShareIcon from "@material-ui/icons/Share";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import LinkIcon from "@material-ui/icons/Link";
import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
import {
  Dialog,
  DialogActions,
  TextField,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Grid,
} from "@material-ui/core";

interface Profiles {
  profiles: {
    id: number;
    uuid: string;
    name: string;
    position: string;
    skills: [{
      name: any;
      level: any;
      keywords: [any];
    }];
    location: string;
    campaigns: [
      {
        id: number;
        campaign_name: string;
        created_at: string;
        startDate: string;
        endDate: string;
      }
    ];
  };
}

function ProfileShared(): JSX.Element {
  const [url, setUrl] = React.useState<string>("");
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [copied, setCopied] = useState("");
  const [emailRecepient, setEmailRecepient] = React.useState<string>("");

  let urlParams: any = useParams();
  const uuid = urlParams.uuid
  const shareLink = window.location.origin + "/share/profile/" + uuid
  let campaignuuid
  try {
    campaignuuid = urlParams.campaignuuid
  } catch (error) {
    campaignuuid = false
  }
  console.log("ProfileShared uuid", uuid)

  const history = useHistory();

  const useStyles = makeStyles({
    floatRight: {
      marginBottom: ".5vh",
      marginLeft: "auto",
      textTransform: "none",
    },
    Button: { textTransform: "none", marginBottom: ".5vh" },
    appBar: {
      height: "56px",
      display: "flex",
      alignContent: "center",
    },
    logo: {
      width: "150px",
      marginBottom: "0.5vh"
    },
    shareModal: {},
    shareAvatar: { width: "35px", height: "35px", background: "#4CA9B2" },
    shareTitle: { marginLeft: "8px" },
    shareTitleGroup: { marginTop: '5px' },
    copyLinkModal: { marginTop: '50%' },
    emailRecipient: {
      backgroundColor: "#EDEEF0",
      borderRadius: "4px",
      marginTop: "10px",
      color: "#4CA9B2",
      "& label.Mui-focused": {
        color: "#4CA9B2",
      },
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#4CA9B2",
      },
      "& .MuiFilledInput-root": {
        background: "#EDEEF0",
      },
    }, dialogActions: {
      marginRight: "15px",
      marginBottom: "12px",
    },
  });

  const classes = useStyles();

  //This function will need to be adjusted, create a function in the controller which takes the :uuid as a variable
  // and retrieves , as opposed to the current way which is retrieving the resume using :id
  useEffect(() => {
    if (uuid) {
      console.log(process.env.REACT_APP_API_URL + "/profiles/" + uuid + "/resume")
      setUrl(process.env.REACT_APP_API_URL + "/profiles/" + uuid + "/resume");
    }
  }, [uuid]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCopied("");
    setEmailRecepient("");
  };

  const handleShare = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: 'include' as RequestCredentials,
      body: JSON.stringify({
        toAddress: emailRecepient
      }),
    };
    fetch(process.env.REACT_APP_API_URL + "/profiles/" + uuid + "/share", requestOptions);
    setOpen(false);
  };

  async function copy() {
    await navigator.clipboard.writeText(shareLink);
    setCopied("Copied");
  }

  return (
    <Box style={{ marginTop: "40px" }}>
      <AppBar className={classes.appBar} position="fixed" color="inherit">
        <Toolbar>
          {campaignuuid ? (
            <Button
              className={classes.Button}
              onClick={history.goBack}
              disableRipple
            >
              <KeyboardBackspaceIcon color="secondary" />
              <Typography style={{ marginLeft: "5px" }}></Typography>
            </Button>) : (<img src="/nodero_logo_horizontal (white background).svg" className={classes.logo} />)}
          <Button
            className={classes.floatRight}
            href={url + "/download"}
            disableRipple
          >
            <GetAppIcon color="secondary" />
            <Typography style={{ marginLeft: "5px" }}>Download</Typography>
          </Button>
          <Button
            disableRipple
            className={classes.Button}
            onClick={handleClickOpen}
          >
            <ShareIcon color="secondary" />
            <Typography style={{ marginLeft: "5px" }}>Share</Typography>
          </Button>


          <Dialog
            className={classes.shareModal}
            open={open}
            onClose={handleClose}
          >
            <DialogTitle>
              <Grid
                className={classes.shareTitleGroup}
                container
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Avatar className={classes.shareAvatar} color="primary">
                  <PersonAddIcon />
                </Avatar>
                <Typography className={classes.shareTitle}>
                  Share with people and groups
                </Typography>
              </Grid>
            </DialogTitle>

            <DialogContent>
              <DialogContentText>
                Share this resume by copying the link below or sending an email
              </DialogContentText>

              <TextField
                onKeyPress={(e) => e.key === "Enter" && handleShare()}
                className={classes.emailRecipient}
                value={emailRecepient}
                onChange={(e) => setEmailRecepient(e.target.value)}
                color="primary"
                margin="dense"
                id="name"
                label="Email Address"
                type="email"
                fullWidth
                size="small"
                variant="filled"
              />
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
              <Button className={classes.Button} onClick={handleClose}>
                Cancel
              </Button>

              <Button
                className={classes.Button}
                variant="outlined"
                color="secondary"
                onClick={copy}
              >
                {!copied ? "Copy Link" : "Copied!"}
              </Button>

              <Button
                className={classes.Button}
                type="submit"
                onClick={handleShare}
                variant="contained"
                color="secondary"
              >
                Share
              </Button>
            </DialogActions>
          </Dialog>
        </Toolbar>
      </AppBar>
      {uuid ? (
        <div
          style={{
            position: "absolute",
            height: "95%",
            width: "100%",
            left: 0,
          }}
        >
          <iframe
            frameBorder="0"
            src={url}
            style={{
              left: 0,
              position: "absolute",
              margin: "0",
              height: "100%",
              width: "100%",
            }}
          ></iframe>
        </div>
      ) : (
        <Typography>LOADING CAMPAIGN...</Typography>
      )}
    </Box>
  );
}

export default ProfileShared;
