import HeaderProfile from "../../components/HeaderProfile";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import { Button, Container, Grid } from "@material-ui/core";
import { Avatar, Typography, TextField, Input } from "@material-ui/core";
import { Link as RouterLink, Route, useHistory } from "react-router-dom";
import CardDisplay from "../../components/ProfileCardDisplay";

// temp import before dynamic data implimented
import logo from "../../images/Nodero-Logo-White.png";
import { display } from "@material-ui/system";
import React from "react";
import ProfileListCardDisplay from "../../components/ProfileListCardDisplay";
import HeaderSimple from "../../components/HeaderSimple";

interface ProfileCardData {
  profiles: {
    id: number;
    uuid: string;
    name: string;
    position: string;
    skills: [{
      name: any;
      level: any;
      keywords: [any];
    }];
    location: string;
    campaign: string | null;
  }[];
  page: string;
  onCheckChange: Function;
  checked: any;
}
interface Props {
  page: string;
}

function AddCampaign(): JSX.Element {
  let test = "test"
  const [filteredData, setFilteredData] =
    React.useState<ProfileCardData["profiles"]>();

  const [checkedProfiles, setCheckedProfiles] = React.useState({})
  const [loading, setLoading] = useState(true);

  //set state of campaign form inputs
  const [campaignName, setCampaignName] = React.useState("");
  const [campaignDescription, setCampaignDescription] = React.useState("");

  //error
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleCheckChange = (event: any) => {
    const uuid = event.currentTarget.value
    const checked = event.currentTarget.checked
    console.log(uuid, checked)
    let temp: any = { ...checkedProfiles }
    temp[uuid] = checked
    console.log(temp)
    setCheckedProfiles(temp)
  }

  const useStyles = makeStyles({
    logo: {
      maxWidth: "100px",
      height: "auto",
      marginBottom: "20px",
      marginTop: "20px",
    },
    title: {
      marginBottom: "32px",
    },
    errorMessage: { color: 'red' }
    ,
    campaignContainer: {
      marginTop: "88px",
      display: "flex",
    },
    profileList: {
      marginTop: "17px",
    },
    summary: {
      backgroundColor: "#EDEEF0",
      borderRadius: "4px",
      marginTop: "17px",
      color: "#4CA9B2",
      "& label.Mui-focused": {
        color: "#4CA9B2",
      },
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#4CA9B2",
      },
      "& .MuiFilledInput-root": {
        background: "#EDEEF0",
      },
    },
    campaignName: {
      backgroundColor: "#EDEEF0",
      borderRadius: "4px",
      width: "488px",
      color: "#4CA9B2",
      "& label.Mui-focused": {
        color: "#4CA9B2",
      },
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#4CA9B2",
      },
      "& .MuiFilledInput-root": {
        background: "#EDEEF0",
      },
    },
    inputFieldList: {
      backgroundColor: "#EDEEF0",
      padding: "0",
      "& li": {
        listStyle: "none",
        padding: "10px",
      },
    },
    saveButton: {
      marginLeft: "8px",
      marginTop: "17px",
      width: "80px",
      height: "32px",
      textTransform: "none",
    },
    cancelButton: {
      marginTop: "17px",
      width: "80px",
      height: "32px",
      textTransform: "none",
    },
    buttonGroup: {
      marginLeft: "65.5%",
    },
  });

  const classes = useStyles();

  const history = useHistory();

  //get profile cards display
  useEffect(() => {
    const getProfiles = async () => {
      const profileData = await fetchProfiles();
      setFilteredData(profileData);
    };
    getProfiles();
    setLoading(false);
  }, []);

  const fetchProfiles = async () => {
    const res = await fetch(process.env.REACT_APP_API_URL + "/profiles", {
      credentials: 'include' as RequestCredentials,
    });
    const data = await res.json();
    console.log(data);
    return data;
  };

  //TODO make profile id selectable and not hard coded
  // Done ^^
  const saveButtonPressed = () => {
    console.log(test)
    const profiles: any = [];
    Object.entries(checkedProfiles).forEach(([key, value]) => {
      if (value) {
        profiles.push({ uuid: key.toString() })
      }
    })
    if (campaignName && campaignDescription) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          campaign_name: campaignName,
          campaign_details: campaignDescription,
          profiles: profiles,
        }),
        credentials: 'include' as RequestCredentials,
      };
      console.log(
        "Campaign Name = ",
        campaignName,
        "Campaign Description = ",
        campaignDescription,
        "Profiles Checked =",
        profiles,
        "Page" + page
      );

      fetch(process.env.REACT_APP_API_URL + "/campaigns/", requestOptions);
      history.goBack();
    } else {
      setErrorMessage("*Please fill in the required fields*")
    }
  };

  const page: string = "campaigns";

  return (
    <Container maxWidth="sm" className={classes.campaignContainer}>
      <Grid
        item
        justifyContent="space-evenly"
        alignItems="flex-start"
        alignContent="stretch"
      >

        <HeaderSimple />

        <h2 className={classes.title}>Add Campaign</h2>

        <div>
          <TextField
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
            name="campaignName"
            autoFocus
            color="primary"
            className={classes.campaignName}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            required
            variant="filled"
            label="Campaign Name"
          ></TextField>
        </div>

        <div>
          <TextField
            value={campaignDescription}
            onChange={(e) => setCampaignDescription(e.target.value)}
            name="campaign_description"
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            size="medium"
            fullWidth
            rows={4}
            variant="filled"
            required
            label="Campaign Description"
            className={classes.summary}
          ></TextField>
        </div>

        <Typography className={classes.errorMessage}>{errorMessage}</Typography>

        <Grid className={classes.profileList}>
          {loading === true ? (
            <Typography>Loading Profiles...</Typography>
          ) : (
            <div>
              {filteredData ? (
                <ProfileListCardDisplay listData={filteredData} page={page} onCheckChange={handleCheckChange} />
              ) : (
                <Typography>No Profiles Found</Typography>
              )}
            </div>
          )}
        </Grid>

        <div className={classes.buttonGroup}>
          <Button
            onClick={history.goBack}
            className={classes.cancelButton}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={saveButtonPressed}
            variant="contained"
            color="secondary"
            className={classes.saveButton}
          >
            Save
          </Button>
        </div>
      </Grid>
    </Container>
  );
}

export default AddCampaign;
