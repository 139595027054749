import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { TextField, Grid, Button } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import CardDisplay from "../components/CampaignCardDisplay";
import { useEffect, useState } from "react";
import { Link, Link as RouterLink } from "react-router-dom";

// structure of main data; array of objects. Data types maybe need to be changed
interface CampaignCardData {
  campaigns: {
    id: number;
    uuid: string;
    campaign_name: string;
    created_at: string;
    startDate: string;
    endDate: string;
    profiles: [
      {
        id: string;
        uuid: string;
        name: string;
        position: string;
        location: string;
      }
    ];
  }[];
}

function Campaigns(): JSX.Element {
  // for displaying loading things while getting data from server
  const [loading, setLoading] = useState(true);

  const useStyles = makeStyles({
    searchBar: {
      minWidth: "320px",
    },
    filterMenu: {
      minWidth: "150px",
    },
    cardGrid: {
      marginTop: "20px",
    },
    subHeader: {},
    filtersHeader: {},
    addButton: { width: "125px", textTransform: "none" },
  });

  const classes = useStyles();

  // SEARCH FUNCTIONALITY

  // dynamically filter profile cards based on text searc
  const handleSearch = (event: React.ChangeEvent<{ value: unknown }>) => {
    let value = event.target.value as string;
    value = value.toLowerCase();
    // an array which will hold the return value(s) of the data that has been searched for
    let result = [];
    // if statement to remove possibility of undefined typescript error; can this ever be false?
    if (campaigns) {
      result = campaigns.filter((data) => {
        // return an array that contains values which is equal to the value entered into the search box
        return data.campaign_name.toLowerCase().search(value) !== -1;
      });
      setFilteredData(result);
    }
  };

  // declare new state variable to hold the data in an array of objects
  const [campaigns, setCampaigns] =
    React.useState<CampaignCardData["campaigns"]>();
  const [filteredData, setFilteredData] =
    React.useState<CampaignCardData["campaigns"]>();

  useEffect(() => {
    // this will retrieve database items on load
    const getCampaigns = async () => {
      const campaignsData = await fetchCampaigns();
      setCampaigns(campaignsData);
      setFilteredData(campaignsData);
    };
    getCampaigns();

    // set loading to false once done retrieving data to populate card array (which is true by default on component load)
    setLoading(false);
  }, []);

  const fetchCampaigns = async () => {
    const res = await fetch(process.env.REACT_APP_API_URL + "/campaigns", {
      credentials: 'include' as RequestCredentials,
    });
    const data = await res.json();
    console.log(data);
    // this needs some code here to format the date of each campaign nicely / or is that api stuff?
    return data;
  };

  const page: string = "campaigns";

  return (
    <Container maxWidth="xl">
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        alignContent="stretch"
        className={classes.subHeader}
        spacing={2}
      >
        <Grid item>
          <Typography variant="h5">
            <b>Campaigns</b>
          </Typography>
        </Grid>

        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            className={classes.filtersHeader}
          >
            {/* filter by search profiles */}
            <Grid item>
              <TextField
                label="Search Campaigns"
                variant="outlined"
                size="small"
                color="secondary"
                className={classes.searchBar}
                onChange={(event) => handleSearch(event)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                className={classes.addButton}
                component={RouterLink}
                to={"/addcampaign"}
              >
                Add Campaign
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes.cardGrid}>
        {loading === true ? (
          <Typography>Loading Campaigns...</Typography>
        ) : (
          <div>
            {filteredData ? (
              <CardDisplay listData={filteredData} page={page} />
            ) : (
              //Display Loading Message
              <Typography></Typography>
            )}
          </div>
        )}
      </Grid>
    </Container>
  );
}

export default Campaigns;

// Header – Title, search campaigns (real time filter?), add campaign button
// Grid – of repeating CampaignCard components. Does the profile card need to a component?
