import HeaderProfile from "../../components/HeaderProfile";
import { makeStyles } from "@material-ui/styles";
import { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Avatar,
  Typography,
  Box,
  Card,
  CardContent,
  IconButton,
  CardHeader,
  Divider,
  Button,
  Grid,
} from "@material-ui/core";
import { Link, useLocation, useParams } from "react-router-dom";
import React from "react";
import { Document, Page, pdfjs } from "react-pdf";

interface LocationState {
  page: string;
}


interface Profiles {
  profiles: {
    id: number;
    name: string;
    position: string;
    skills: [{
      name: any;
      level: any;
      keywords: [any];
    }];
    location: string;
    campaigns: [
      {
        id: number;
        campaign_name: string;
        created_at: string;
        startDate: string;
        endDate: string;
      }
    ];
  };
}

function Profile(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [profiles, setProfiles] = React.useState<Profiles["profiles"]>();
  const [url2, setUrl2] = React.useState<string>("");

  const location = useLocation<LocationState>();
  let urlParams: any = useParams();
  const profileID = urlParams.uuid
  console.log("Profile", profileID)

  const useStyles = makeStyles({
    inputFieldList: {
      padding: "0",
      "& li": {
        listStyle: "none",
        padding: "10px",
      },
      logo: {
        maxWidth: "300px",
        height: "auto",
        marginBottom: "20px",
      },
      campaignContainer: {
        textAlign: "center",
      },
      summary: {
        maxWidth: "600px",
        margin: "auto",
      },
      cardGrid: {
        margin: "auto",
        marginTop: "20px",
      },
    },
    //   iframecontainer:{
    //     overflow: 'hidden',
    //     /* 16:9 aspect ratio */
    //     paddingTop: '56.25%',
    //     position: 'relative'
    //   },
    //   iframeResume: { overflow: "hidden",left:0,top:0, height: "100%",width:'100%', position:'absolute'},
    // },
  });

  const classes = useStyles();

  useEffect(() => {
      const tempUrl2 =
        process.env.REACT_APP_API_URL + "/profiles/" + profileID + "/resume";

      setUrl2(tempUrl2);
    }, []);

  return (
    <Box style={{ marginTop: "40px" }}>
      <HeaderProfile />
      {loading === true ? (
        <Typography>Loading Profiles...</Typography>
      ) : (
        <div
          style={{
            position: "absolute",
            height: "95%",
            width: "100%",
            left: 0,
          }}
        >
          <iframe
            frameBorder="0"
            src={url2}
            style={{
              left: 0,
              position: "absolute",
              margin: "0",
              height: "100%",
              width: "100%",
            }}
          ></iframe>
        </div>
      )}
    </Box>
  );
}

export default Profile;
