import React from "react";
import { makeStyles } from "@material-ui/styles";
import Avatar from "@material-ui/core/Avatar";
import {
  Typography,
  List,
  Checkbox,
  ListItemAvatar,
  ListItemText,
  Box,
} from "@material-ui/core";
import ListItemButton from "@material-ui/core/ListItem";
import ListItem from '@material-ui/core/ListItem';
import CheckBox from "@material-ui/core/Checkbox";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import { Link as RouterLink, useParams } from "react-router-dom";
import { profile } from "console";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import GroupsIcon from "@material-ui/icons/GroupSharp";

interface Props {
  profiles: {
    id: number;
    uuid: string;
    name: string;
    position: string;
    skills: [{
      name: any;
      level: any;
      keywords: [any];
    }];
    location: string;
    campaign: string | null;
  };
  onCheckChange: Function;
}

function ProfileListCard(props: Props): JSX.Element {
  const useStyles = makeStyles({
    container: {
      maxWidth: '488px', padding: '0px'
    },
    card: {
      boxShadow: "0px 0px 10px #1C242C1A",
      padding: "16px",
      paddingBottom: "16px",
    },
    cardText: {
      fontSize: "18px",
    },
    cardGridText: {
      textAlign: "left",
      marginLeft: "5px",
    },
    profileAvatar: { width: "32px", height: "32px" },
    profileTitle: { width: '150px' },
    profilePosition: { width: '100px' },
    mainList: { padding: '0px' },
  });

  const classes = useStyles();
  const [campaignProfiles, setCampaignProfiles] = React.useState("");
  // console.log("Campaign Profiles = " + campaignProfiles)
  const profileId = `${props.profiles["uuid"]}`
  let urlParams: any = useParams();
  const campaignID = urlParams.uuid
  // console.log("ProfileListCard", campaignID)

  let isChecked = false;
  if (campaignID) {
    console.log("props", props)
    if (props.profiles.campaign) {
      console.log("campaigns", props.profiles.campaign)
      // console.log("Yes it is")
      isChecked = true
    }
  }

  return (
    <List dense className={classes.container}>
      {[0].map((value) => {
        const labelId = `checkbox-list-secondary-label-${value}`;
        return (
          <ListItem key={profileId} id={profileId} value={profileId} className={classes.mainList}>
            <ListItemButton>
              <ListItemAvatar>
                <Avatar
                  alt={`Avatar n°${props.profiles["name"]}`}
                  src={`https://ui-avatars.com/api/?name=${props.profiles.position}&color=FFFF&background=4CA9B2`}
                />
              </ListItemAvatar>
              <ListItemText className={classes.profileTitle} id={labelId} primary={<Typography variant="body2" style={{ fontSize: '14px', fontWeight: 'bold' }}>{props.profiles["name"]}</Typography>} />
              <ListItemText className={classes.profilePosition} id={labelId} primary={<Typography variant="body2" style={{ fontSize: '13px' }}>{props.profiles["position"]}</Typography>} />
            </ListItemButton>
            <Checkbox name={profileId} value={profileId} onChange={(e) => { console.log(e.currentTarget); props.onCheckChange(e) }} defaultChecked={isChecked} />
          </ListItem>
        );
      })}
    </List>
  );
}

export default ProfileListCard;
