import HeaderProfile from "../../components/HeaderProfile";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import { Button, Container, Grid } from "@material-ui/core";
import { Avatar, Typography, TextField, Input } from "@material-ui/core";
import CardDisplay from "../../components/ProfileCardDisplay";

// temp import before dynamic data implimented
import logo from "../../../images/Nodero-Logo-White.png";
import { display } from "@material-ui/system";
import React from "react";
import ProfileListCardDisplay from "../../components/ProfileListCardDisplay";
import Header from "../../components/Header";
import HeaderSimple from "../../components/HeaderSimple";
import { useHistory, useLocation, useParams } from "react-router-dom";

interface ProfileCardData {
  profiles: {
    id: number;
    uuid: string;
    parentuuid: string;
    name: string;
    position: string;
    skills: [{
      name: any;
      level: any;
      keywords: [any];
    }];
    location: string;
    campaign: string | null;
  }[];
  page: string;
  onCheckChange: Function;
  checked: any;
}
interface LocationState {
  campaignID: number;
}
function EditCampaign(): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] =
    React.useState<ProfileCardData["profiles"]>();

  const [checkedProfiles, setCheckedProfiles] = React.useState({})
  const [errorMessage, setErrorMessage] = React.useState("");

  const history = useHistory();
  let urlParams: any = useParams();
  const campaignID = urlParams.uuid
  console.log("EditCampaigns", campaignID)

  const [campaignName, setCampaignName] = React.useState("");
  const [campaignDescription, setCampaignDescription] = React.useState("");

  const useStyles = makeStyles({
    logo: {
      maxWidth: "100px",
      height: "auto",
      marginBottom: "20px",
      marginTop: "20px",
    },
    errorMessage: { color: 'red' },
    title: {
      marginBottom: "40px",
    },
    campaignContainer: {
      marginTop: "88px",
      display: "flex",
    },
    summary: {
      backgroundColor: "#EDEEF0",
      borderRadius: "4px",
      maxWidth: "488px",
      minWidth: "488px",
      margin: "auto",
      marginTop: "17px",
      "& label.Mui-focused": {
        color: "#4CA9B2",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#4CA9B2",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#4CA9B2",
        },
        "&:hover fieldset": {
          borderColor: "#4CA9B2",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#4CA9B2",
        },
      },
    },
    cardGrid: {
      margin: "0",
      marginTop: "0px",
    },
    campaignName: {
      backgroundColor: "#EDEEF0",
      borderRadius: "4px",
      width: "488px",
      color: "#4CA9B2",
      "& label.Mui-focused": {
        color: "#4CA9B2",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#4CA9B2",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#4CA9B2",
        },
        "&:hover fieldset": {
          borderColor: "#4CA9B2",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#4CA9B2",
        },
      },
    },
    inputFieldList: {
      backgroundColor: "#EDEEF0",
      padding: "0",
      "& li": {
        listStyle: "none",
        padding: "10px",
      },
    },
    saveButton: { marginLeft: '5px', marginTop: '17px', width: "80px", height: "32px", textTransform: "none" },
    cancelButton: { marginTop: '17px', width: "80px", height: "32px", textTransform: "none" },
    profileList: {
      marginTop: "17px",
    },
    buttonGroup: {
      marginLeft: "58%",
      marginBottom: "5%",
    },
  });

  const classes = useStyles();

  const handleCheckChange = (event: any) => {
    const id = event.currentTarget.value
    const checked = event.currentTarget.checked
    console.log(id, checked)
    let temp: any = { ...checkedProfiles }
    temp[id] = checked
    console.log(temp)
    setCheckedProfiles(temp)
  }

  useEffect(() => {
    // this will retrieve database items on load
    const getProfiles = async (checkedProfiles: any) => {
      console.log("checkedProfiles", checkedProfiles)
      const profilesData = await fetchProfiles();
      console.log("Profiles Data", profilesData)
      let newProfileData: any[] = []
      for (let profile of profilesData) {
        if (Object.keys(checkedProfiles).includes(profile.uuid)) {
          console.log("bruh")
          profile.campaign = campaignID;
        }
        newProfileData = newProfileData.concat(profile)
      }
      setFilteredData(profilesData);
    };
    const getCampaign = async () => {
      const campaign = await fetchCampaign();
      setCampaignDescription(campaign.campaign_details)
      setCampaignName(campaign.campaign_name)
      let first: any = {}
      campaign.profiles.forEach((profile: any) => {
        first[profile.parentuuid] = true
      })
      setCheckedProfiles(first)
      getProfiles(first);
    }
    getCampaign();

    // set loading to false once done retrieving data to populate card array (which is true by default on component load)
    setLoading(false);
  }, []);

  const fetchProfiles = async () => {
    const res = await fetch(process.env.REACT_APP_API_URL + "/profiles", {
      credentials: 'include' as RequestCredentials,
    });
    const data = await res.json();
    console.log("All The Profiles:", data);
    return data;
  };

  const fetchCampaign = async () => {
    const res = await fetch(process.env.REACT_APP_API_URL + "/campaigns/" + campaignID, {
      credentials: 'include' as RequestCredentials,
    });
    const data = await res.json();
    console.log("campaign", data);
    return data;
  };

  const page: string = "campaigns";

  const saveButtonPressed = async () => {
    console.log("test")
    const profiles: any = [];
    Object.entries(checkedProfiles).forEach(([key, value]) => {
      if (value) {
        profiles.push({ uuid: key.toString() })
      }
    })
    if (campaignName && campaignDescription) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: 'include' as RequestCredentials,
        body: JSON.stringify({
          campaign_name: campaignName,
          campaign_details: campaignDescription,
          profiles: profiles,
        }),
      };
      console.log(
        "Campaign Name = ",
        campaignName,
        "Campaign Description = ",
        campaignDescription,
        "Profiles Checked =",
        profiles,
        "Page" + page
      );

      await fetch(process.env.REACT_APP_API_URL + "/campaigns/" + campaignID, requestOptions);
      history.goBack();
    } else {
      setErrorMessage("*Please fill in the required fields*")
    }
  };

  const deleteButtonPressed = () => {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      credentials: 'include' as RequestCredentials,
    };
    console.log("Request options", requestOptions)
    fetch(process.env.REACT_APP_API_URL + "/campaigns/" + campaignID, requestOptions);
    history.go(-2);
  }
  return (
    <Container maxWidth="lg" className={classes.campaignContainer}>
      <Grid container >
        {/* <Grid item>
          <h4>Section</h4>
          <Typography>EDIT</Typography>
          <Typography>Profiles</Typography>
          <Typography>Permissions</Typography>
        </Grid> */}
        <Grid item>
          <HeaderSimple />
          <h2 className={classes.title}>Edit Campaign</h2>
          {/* <img src={logo} alt="logo" className={classes.logo} /> */}
          <div>
            <TextField
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
              name="campaignName"
              autoFocus
              color="primary"
              className={classes.campaignName}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              required
              variant="filled"
              label="Campaign Name"
            ></TextField>
          </div>
          <div>
            <TextField
              value={campaignDescription}
              onChange={(e) => setCampaignDescription(e.target.value)}
              name="campaign_description"
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              size="medium"
              fullWidth
              rows={4}
              variant="filled"
              required
              label="Campaign Description"
              className={classes.summary}
            ></TextField>
          </div>

          <Typography className={classes.errorMessage}>{errorMessage}</Typography>

          <Grid className={classes.profileList}>
            {loading === true ? (
              <Typography>Loading Profiles...</Typography>
            ) : (
              <div>
                {filteredData ? (
                  <ProfileListCardDisplay listData={filteredData} page={page} onCheckChange={handleCheckChange} />
                ) : (
                  <Typography>No Profiles Found</Typography>
                )}
              </div>
            )}
          </Grid>

          <div className={classes.buttonGroup}>
            <Button
              onClick={history.goBack}
              className={classes.cancelButton}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              onClick={saveButtonPressed}
              variant="contained"
              color="secondary"
              className={classes.saveButton}
            >
              Save
            </Button>
            <Button
              onClick={deleteButtonPressed}
              className={classes.cancelButton}
              variant="contained"
            >
              Delete
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default EditCampaign;

// Header – Back to profiles button*, edit, download, share
// JSON resume, needs restyle? What does JSON R offer already?
// *This button might need to do different things depending on where to profile was accessed from. Could be accessed from Profiles Subpage OR Campaign
