import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { Link, Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";

// temp import before dynamic data implimented
import logo from "../images/Nodero-Logo-White.webp";

interface Props {
  page: string;
}

function Header(props: Props): JSX.Element {
  const useStyles = makeStyles({
    topLogo: {
      marginRight: "2%",
      maxWidth: "120px",
      height: "auto",
    },
    profileText: {
      textTransform: "none",
      marginLeft: "38%",
      marginBottom:".5vh"
    },
    campaignText: {
      textTransform: "none",
      marginBottom:".5vh"
    },
    floatRight: {
      marginLeft: "auto",
    },
    menuSpacing: {
      marginLeft: "2%",
      marginBottom:".5vh"
    },
    contentSpacing: {
      marginTop: "90px",
    },
    appBar:{
      height:'56px',
      display:'flex'
    },
    avatarUser: {
      marginLeft: "2%",
      marginBottom:".5vh",
      width:'30px',
      height:'30px'
    }
  });

  const classes = useStyles();

  const [username, setUsername] = useState("");

  useEffect(() => {
    // temp value - this will be dynamic once we have auth data
    const username = "Admin";

    setUsername(username);
  }, []);

  return (
    <Container>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar>
          <Link to="/">
            <img src={logo} alt="logo" className={classes.topLogo} />
          </Link>
          {/* nav buttons with variable styles/content depending on the page prop */}
          
          <Button
            component={RouterLink}
            to="/"
            className={classes.profileText}
            disableRipple
            color={props.page === "profiles" ? "secondary" : "inherit"}
            // style={{
            //   borderBottom: props.page === "profiles" ? "solid" : "none",
            // }}
          >
            Profiles
          </Button>
          <Button
          className={classes.campaignText}
            component={RouterLink}
            to="/campaigns"
            disableRipple
            color={props.page === "campaigns" ? "secondary" : "inherit"}
            // style={{
            //   borderBottom: props.page === "campaigns" ? "solid" : "none",
            // }}
          >
            Campaigns
          </Button>
          
          {/*
          Button Removed and placed into the profiles & campaigns pages
          <Button
            variant="contained"
            color="secondary"
            className={classes.addButton}
            component={RouterLink}
            to={props.page === "profiles" ? "/addprofile" : "/addcampaign"}
          >
            {props.page === "profiles" ? "Add Profile" : ""}
            {props.page === "campaigns" ? "Add Campaign" : ""}
          </Button> */}

          <div className={classes.floatRight}>
            <NotificationsIcon />
          </div>
          <Typography className={classes.menuSpacing}>{username}</Typography>
          <Avatar      
            className={classes.avatarUser}
            component={RouterLink}
            to="/login"
          ></Avatar>
        </Toolbar>
      </AppBar>
      <Typography className={classes.contentSpacing}></Typography>
    </Container>
  );
}

export default Header;

// Left - Company logo, links (profiles, campaigns, templates), create button*,
// Right - Bell**, Username, User Avatar (click for a menu maybe?)
// *Does button change what is does based on subpage? What is it creating?
// **What does the bell notify?

// React change navbar based on page - pressed effect for current page

// https://non-traditional.dev/the-ultimate-responsive-grid-component-in-react-d4c72ebffb57
