import React, { useEffect } from 'react';
import './App.css';
import { Container, Box } from '@material-ui/core';
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
// import login from './pages'; 
import Main from './pages/Main';
import Login from './pages/Login';
import Profile from './pages/view/Profile';
import Campaign from './pages/view/Campaign';
import AddProfile from './pages/add/AddProfile';
import AddCampaign from './pages/add/AddCampaign';
import Profiles from './pages/Profiles';
import EditCampaign from './pages/edit/EditCampaign';
import EditProfile from './pages/edit/EditProfile';
import ProfileShared from './pages/view/ProfileShared';
import CampaignShared from './pages/view/CampaignShared';
import { NavigateBefore } from '@material-ui/icons';
import Test from './components/Test';

require('dotenv').config();


function App(): JSX.Element {
  return (
    <Box>
      <Container style={{ maxWidth: '95%' }}>
        <Router>
          <Test />
          <Switch>
            <Route path="/login"><Login /></Route>

            <Route path="/addcampaign"><AddCampaign /></Route>
            <Route path="/addcompany"></Route>
            <Route path="/addprofile"><AddProfile /></Route>

            <Route path="/campaign/:campaignuuid/profile/:uuid"><Profile /></Route>
            <Route path="/campaign/:uuid"><Campaign /></Route>
            <Route path="/company/:id"></Route>
            <Route path="/profile/:uuid"><Profile /></Route>

            <Route path="/share/campaign/:campaignuuid/profile/:uuid"><ProfileShared /></Route>
            <Route path="/share/campaign/:uuid"><CampaignShared /></Route>
            <Route path="/share/profile/:uuid"><ProfileShared /></Route>

            {/* <Route path="/profile/:id/^(\{{0,1}([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}\}{0,1})"><Profile/></Route> */}

            <Route path="/editcampaign/:uuid"><EditCampaign /></Route>
            <Route path="/editcompany/:uuid"></Route>

            {/* Do we reference Profile in the route? */}
            <Route path="/editprofile/:uuid"><EditProfile /></Route>

            <Route path="/" ><Main /></Route>

            <Route path="/*" ><Main /></Route>
          </Switch>
        </Router>
      </Container>
    </Box>
  );
}

export default App;

// need to add auth
// auth comes from login as a company OR from following a link sent to a client which brings them to a main view with many component parts disabled