import React from "react";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import GroupIcon from "@material-ui/icons/Group";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Typography, Chip, CardActionArea, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import { Link as RouterLink } from "react-router-dom";
import { profile } from "console";
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import GroupsIcon from '@material-ui/icons/GroupSharp';

interface Props {
  profile: {
    id: number;
    uuid: string;
    name: string;
    position: string;
    skills: [{
      name: any;
      level: any;
      keywords: [any];
    }];
    location: string;
    campaigns: [
      // {
      //   id: number;
      //   campaign_name: string;
      //   created_at: string;
      //   startDate: string;
      //   endDate: string;
      // }
      any
    ]
  },
  campaign?: string;
}

function ProfileCard(props: Props): JSX.Element {
  const useStyles = makeStyles({
    container: {
      alignContent: "center",
    },
    card: {
      boxShadow: "0px 0px 10px #1C242C1A",
      padding: "16px",
      paddingBottom: "16px",

    },
    cardText: {
      fontSize: '18px'
    },
    cardGridText: {
      textAlign: "left",
      marginLeft: "5px",
    },
    viewButton: {
      height: '24px',
      width: '89px',
      borderColor: "#EEF0F6",
      color: "#1C242C",
      backgroundColor: "#EEF0F6",
      textTransform: "none",
      fontSize: '12px',
    },
    campaignButton: {
      height: '24px',
      width: '113px',
      borderColor: "#EEF0F6",
      color: "#1C242C",
      backgroundColor: "#EEF0F6",
      textTransform: "none",
      marginLeft: "08px",
      fontSize: '12px',
    },
    profileAvatar: { width: "80px", height: "80px" },
    eyeIcon: { height: '15px', width: '20px', color: '#4CA9B2', marginRight: '2px' },
    groupIcon: { height: '15px', width: '20px', color: '#4CA9B2', marginRight: '2px' }
  });

  const classes = useStyles();
  const { profile } = props;

  return (
    <CardActionArea
      className={classes.container}
      component={RouterLink}
      to={{
        pathname: `${props.campaign ? props.campaign : '/'}profile/${props.profile.uuid}`
      }}
    >
      <Card className={classes.card}>
        <CardContent>
          <Grid
            container
            spacing={1}
            alignContent="center"
            justifyContent="flex-start"
          >
            {/* //TODO Instead of profile pictures display BA for business analyst SA for security advisor etc  */}
            <Grid item zeroMinWidth>
              <Avatar
                className={classes.profileAvatar}
                src={`https://ui-avatars.com/api/?name=${profile.position}&color=FFFF&background=4CA9B2`}
              ></Avatar>
            </Grid>

            <Grid item zeroMinWidth className={classes.cardGridText}>
              <Typography className={classes.cardText}>
                <b>{profile["name"]}</b>
              </Typography>
              <Typography className={classes.cardText}>
                {profile["position"]}
              </Typography>

              {/* {profile.campaigns ? (
                <Typography className={classes.cardText} >
                  {profile.campaigns.length}
                </Typography>
              ) : (
                //Display Loading Message
                <Typography>No Profile.campaigns</Typography>
              )} */}

              {/* Views and campaigns chips */}

              {!props.campaign ? (
                <Grid style={{ paddingTop: "5px" }}>
                  <Button
                    className={classes.viewButton}
                    variant="outlined"
                    color="secondary"
                    size="small"
                  ><RemoveRedEye className={classes.eyeIcon} />
                    {" "}
                    {Math.floor((Math.random() * 10) + 1) + " Views"}
                  </Button>

                  <Button
                    className={classes.campaignButton}
                    variant="outlined"
                    color="secondary"
                    size="small"
                    component={RouterLink}
                    to={{
                      pathname: "/campaigns",
                    }}
                  ><GroupIcon className={classes.groupIcon} />
                    {profile.id + " Campaigns"}
                  </Button>
                </Grid>) : (<></>)
              }

              {/* //TODO Display how many campaigns a profile is assigned to
            {props.profiles.campaigns.map((campaigns) => (
              <Typography>{campaigns.id}</Typography>
            ))} */}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </CardActionArea>
  );
}

export default ProfileCard;
