import ProfileCard from "./ProfileCard";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import ProfileListCard from "./ProfileListCard";

interface Props {
  listData: {
    id: number;
    uuid: string;
    name: string;
    position: string;
    skills: [{
      name: any;
      level: any;
      keywords: [any];
    }];
    location: string;
    campaign: string | null;
  }[];
  page: string;
  onCheckChange: Function;
}

// TODO add media query which changes maxwidth to 100% when device view is smaller than 600
function ProfileListCardDisplay(props: Props): JSX.Element {
  const useStyles = makeStyles({
    gridContainer: { width: "488px" },
    gridItemProfile: {
      padding: "8px",
      minWidth: "100%",
      minHeight: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
      borderRadius: "4px",
      background: "#FFFFFF",
    },
  });

  const classes = useStyles();
  return (
    <Grid
      container
      spacing={1}
      className={classes.gridContainer}
      justifyContent={props.page === "profiles" ? "flex-start" : "center"}
    >
      {props.listData.map(
        item => {
          return (
            <Grid key={item.uuid} item className={classes.gridItemProfile}>
              <ProfileListCard key={item.uuid} profiles={item} onCheckChange={props.onCheckChange} />
            </Grid>
          );
        }
      )}
    </Grid>
  );
}

export default ProfileListCardDisplay;

// this component will be used to structure the grids for profiles. It could have functionality to change layouts on clicks

// https://fettblog.eu/typescript-react/prop-types/
