import React from "react";
import CampaignCard from "./CampaignCard";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";

interface Props {
  listData: {
    id: number;
    uuid: string;
    campaign_name: string;
    created_at: string;
    startDate: string;
    endDate: string;
    profiles: [
      {
        id: string;
        uuid: string;
        name: string;
        position: string;
        location: string;
      }
    ];
  }[];
  page: string;
}

function CardDisplay(props: Props): JSX.Element {
  const useStyles = makeStyles({
    gridContainer: {},
    gridItemCampaign: {
      minWidth: "25%",
      ["@media(max-width:1025px)"]: { minWidth: "100%" },
      minHeight: "25%",
      maxWidth: "50%",
      maxHeight: "50%",
    },
  });

  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.gridContainer}>
      {props.listData.map(item => {
        return (
          <Grid
            key={item.uuid}
            item
            xs={7}
            sm={7}
            md={7}
            lg={2}
            // justifyContent="space-around"
            className={classes.gridItemCampaign}
          >
            <CampaignCard key={item.uuid} content={item} />
          </Grid>
        );
      }
      )}
    </Grid>
  );
}

export default CardDisplay;

// this component will be used to structure the grids for campaigns. It could have functionality to change layouts on clicks

// https://fettblog.eu/typescript-react/prop-types/
