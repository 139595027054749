import ProfileCard from "./ProfileCard";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import Campaign from "../pages/view/Campaign";

interface Props {
  listData: {
    id: number;
    uuid: string;
    name: string;
    position: string;
    skills: [{
      name: any;
      level: any;
      keywords: [any];
    }];
    location: string;
    campaigns: [
      // {
      //   id: number;
      //   campaign_name: string;
      //   created_at: string;
      //   startDate: string;
      //   endDate: string;
      // }
      any
    ];
  }[];
  page: string;
  campaign?: string
}

// TODO add media query which changes maxwidth to 100% when device view is smaller than 600
function ProfileCardDisplay(props: Props): JSX.Element {
  const useStyles = makeStyles({
    gridContainer: {},
    gridItemProfile: {
      minWidth: "25%",
      ['@media(max-width:1025px)']: { minWidth: '100%' },
      minHeight: "25%",
      maxWidth: "50%",
      maxHeight: "50%",
    },
  });

  const classes = useStyles();
  console.log("listdata", props.listData)
  return (
    <Grid
      container
      spacing={2}
      className={classes.gridContainer}
      justifyContent={props.page === "profiles" ? "flex-start" : "center"}
    >
      {props.listData.map(item => {
        return (
          <Grid
            key={item.uuid}
            item
            xs={8}
            sm={8}
            md={7}
            lg={4}
            xl={2}
            className={classes.gridItemProfile}
          >
            <ProfileCard key={item.uuid} profile={item} campaign={props.campaign} />
          </Grid>
        );
      }
      )}
    </Grid>
  );
}

export default ProfileCardDisplay;

// this component will be used to structure the grids for profiles. It could have functionality to change layouts on clicks

// https://fettblog.eu/typescript-react/prop-types/
