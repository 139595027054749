import HeaderProfile from "../../components/HeaderProfile";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@material-ui/core";
import CardDisplay from "../../components/ProfileCardDisplay";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import { Link as RouterLink } from "react-router-dom";
import ProfileCard from "../../components/ProfileCard";

// temp import before dynamic data implimented
import logo from "../../images/Nodero-Logo-White.png";
import { AvatarGroup } from "@material-ui/lab";
import HeaderSimple from "../../components/HeaderSimple";
import HeaderCampaign from "../../components/HeaderCampaign";

interface Campaign {
  campaign: {
    id: number;
    uuid: string;
    campaign_name: string;
    campaign_details: string;
    profiles: [
      {
        id: string;
        name: string;
        position: string;
        location: string;
      }
    ];
  };
}
interface ProfileCardData {
  profiles: {
    id: number;
    uuid: string;
    name: string;
    position: string;
    skills: [{
      name: any;
      level: any;
      keywords: [any];
    }];
    location: string;
    campaigns: [
      {
        id: number;
        campaign_name: string;
        created_at: string;
        startDate: string;
        endDate: string;
      }
    ];
  }[];
}

function Campaign(): JSX.Element {
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);

  const useStyles = makeStyles({
    logo: {
      maxWidth: "300px",
      height: "auto",
      marginBottom: "20px",
    },
    campaignContainer: {
      textAlign: "center",
      marginTop: "100px"
    },
    summary: {
      maxWidth: "600px",
      margin: "auto",
    },
    cardGrid: {
      margin: "auto",
      marginTop: "40px",
    },
    noProfileMessage: {
      margin: 'auto'
    },
  });

  const classes = useStyles();
  const [campaign, setCampaign] = React.useState<Campaign["campaign"]>();
  const [filteredData, setFilteredData] =
    React.useState<ProfileCardData["profiles"]>();

  let urlParams: any = useParams();
  const campaignID = urlParams.uuid
  console.log("Campaign", campaignID)
  const page: string = "campaigns";

  //get campaign
  useEffect(() => {
    const getCampaign = async () => {
      const campaignData = await fetchCampaign();
      setCampaign(campaignData);
    };
    getCampaign();
    setLoading1(false);
    setLoading2(false);
  }, []);

  const fetchCampaign = async () => {
    const fetchURL = process.env.REACT_APP_API_URL + "/campaigns/" + campaignID;
    const res = await fetch(fetchURL, {
      credentials: 'include' as RequestCredentials,
    });
    const data = await res.json();
    return data;
  };

  //get profile cards display
  useEffect(() => {
    const getProfiles = async () => {
      const profileData = await fetchProfiles();
      setFilteredData(profileData);
    };
    getProfiles();
    setLoading2(false);
  }, []);

  const fetchProfiles = async () => {
    const res = await fetch(process.env.REACT_APP_API_URL + "/campaigns/" + campaignID + "/profiles", {
      credentials: 'include' as RequestCredentials,
    });
    const data = await res.json();
    console.log(data);
    return data;
  };

  return (
    <Container
      maxWidth="xl"
      className={classes.campaignContainer}
    >
      {loading1 === true ? (
        <Typography>Loading Campaign...</Typography>
      ) : (
        <div>
          <HeaderCampaign />
          <div>
            <img src={logo} alt="logo" className={classes.logo} />
          </div>
          {campaign ? (
            <div>
              <Typography variant="h6" color="secondary">
                <b>{campaign.campaign_name}</b>
              </Typography>
              <Typography className={classes.summary}>
                {campaign.campaign_details}
              </Typography>
            </div>
          ) : (
            <Typography>No Campaign</Typography>
          )}
          <Grid className={classes.cardGrid}>
            {loading2 === true ? (
              <Typography>Loading Profiles...</Typography>
            ) : (
              <div>
                {filteredData ? (
                  <CardDisplay listData={filteredData} page={page} />
                ) : (
                  //Display Message when no profiles are assigned to a campaign
                  <Typography className={classes.noProfileMessage}>
                    There are no profiles assigned to this campaign
                  </Typography>
                )}
              </div>
            )}
          </Grid>
        </div>
      )}
    </Container>
  );
}

export default Campaign;

// Header – back to campaigns button, edit, download, share
// Campany logo
// Title, and details
// Grid of participants
