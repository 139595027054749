import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {
  Button,
  TextField,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import CardDisplay from "../components/ProfileCardDisplay";
import Profile from "./view/Profile";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import { Link, Link as RouterLink, useHistory } from "react-router-dom";

// structure of main data; array of objects
interface ProfileCardData {
  profiles: {
    id: number;
    uuid: string;
    name: string;
    position: string;
    skills: [{
      name: any;
      level: any;
      keywords: [any];
    }],
    location: string;
    campaigns: [
      // {
      //   id: number;
      //   campaign_name: string;
      //   created_at: string;
      //   startDate: string;
      //   endDate: string;
      // }
      any
    ];
  }[];
}

function Profiles(): JSX.Element {
  // for displaying loading things while getting data from server
  const [loading, setLoading] = useState(true);
  // const history = useHistory();

  const useStyles = makeStyles({
    searchBar: {
      minWidth: "320px",
    },
    filterMenu: {
      minWidth: "150px",
    },
    cardGrid: {
      marginTop: "20px",
    },
    subHeader: {},
    filtersHeader: {},
    addButton: { width: "125px", textTransform: "none" },
  });
  const classes = useStyles();

  // FILTER MENUS FUNCTIONALITY - state is used to tell the component which value to display
  const [search, setSearch] = useState("");
  const [position, setPosition] = useState("");
  const [skills, setSkill] = useState("");
  const [location, setLocation] = useState("");

  const searchChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSearch(event.target.value as string);
    handleSearch(event, "search", "");
  };
  const positionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPosition(event.target.value as string);
    handleSearch(event, "filter", "position");
  };
  const skillChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSkill(event.target.value as string);
    handleSearch(event, "filter", "skill");
  };
  const locationChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLocation(event.target.value as string);
    handleSearch(event, "filter", "location");
  };

  // DYNAMICALLY FILTER PROFILE CARDS based on text search and set filters; maybe move this function to exernal file for neatness?
  const handleSearch = (
    event: React.ChangeEvent<{ value: unknown }>,
    type: string,
    menu: string
  ) => {
    let value = event.target.value as string;
    value = value.toLowerCase();
    // array to be filtered, removing results for each filter
    let data = profiles;
    // an array which will hold the return value(s) of the data that has been searched for
    let result: ProfileCardData["profiles"] = [];

    // outside if statement only to remove "possibility of undefined" typescript error; can this ever be false? better way to do this?
    if (data) {
      // series of filters that check the menus then filter before the search
      // outside conditionals checks if the menu is being updated itself or being used for another update
      // this is to remove the delay cause by hooks being async; it allows the event value to be used on the menu being updated instead of the state value which will be one update behind
      if (menu === "position") {
        data = data.filter((profile) => {
          return profile.position.toLowerCase().search(value) !== -1;
        });
      } else {
        if (position !== "") {
          data = data.filter((profile) => {
            return profile.position.toLowerCase().search(position) !== -1;
          });
        }
      }
      // TODO: Skills are an array and not a string, so need to treat them as such
      // if (menu === "skills") {
      //   data = data.filter((profile) => {
      //     return profile.skills.toLowerCase().search(value) !== -1;
      //   });
      // } else {
      //   if (skills !== "") {
      //     data = data.filter((profile) => {
      //       return profile.skills.toLowerCase().search(skills) !== -1;
      //     });
      //   }
      // }

      if (menu === "location") {
        data = data.filter((profile) => {
          return profile.location.toLowerCase().search(value) !== -1;
        });
      } else {
        if (location !== "") {
          data = data.filter((profile) => {
            return profile.location.toLowerCase().search(location) !== -1;
          });
        }
      }

      if (type === "search") {
        result = data.filter((profile) => {
          // return an array that contains values which is equal to the value entered into the search box
          return (
            // text search logic - search all the parameters
            profile.name.toLowerCase().search(value) !== -1 ||
            profile.position.toLowerCase().search(value) !== -1 ||
            // profile.skills.toLowerCase().search(value) !== -1 ||
            profile.location.toLowerCase().search(value) !== -1
          );
        });
      }

      if (type === "filter") {
        // console.log(data)
        result = data.filter((profile) => {
          // return an array that contains values which is equal to the value entered into the search box
          return (
            // text search logic - search all the parameters
            profile.name.toLowerCase().search(search) !== -1 ||
            profile.position.toLowerCase().search(search) !== -1 ||
            //profile.skills.toLowerCase().search(search) !== -1 ||
            profile.location.toLowerCase().search(search) !== -1
          );
        });
      }
    }
    setFilteredData(result);
  };

  // functions for populating menu filter optioms with dynamic data
  function onlyUnqiue(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
  }
  const getUniquePositions = () => {
    if (profiles) {
      console.log("if profiles", profiles, !profiles)
      return profiles
        .map((m) => m.position)
        .filter(onlyUnqiue)
        .sort();
    } else {
      return [""];
    }
  };
  const getUniqueSkills = () => {
    if (profiles) {
      return profiles
        .map((m) => m.skills)
        .filter(onlyUnqiue)
        .sort();
    } else {
      return [""];
    }
  };
  const getUniqueLocations = () => {
    if (profiles) {
      return profiles
        .map((m) => m.location)
        .filter(onlyUnqiue)
        .sort();
    } else {
      return [""];
    }
  };

  // declare new state variable to hold the data in an arrays of objects
  const [profiles, setProfiles] = React.useState<ProfileCardData["profiles"]>();
  const [filteredData, setFilteredData] =
    React.useState<ProfileCardData["profiles"]>();

  useEffect(() => {
    console.log("REACT VERSION", React.version)
    // this will retrieve database items on load
    const getProfiles = async () => {
      const profilesData = await fetchProfiles();
      console.log("ProfilesData", profilesData)
      setProfiles(profilesData);
      setFilteredData(profilesData);
    };
    getProfiles();
    // set loading to false once done retrieving data to populate card array (which is true by default on component load)
    setLoading(false);
  }, []);

  const fetchProfiles = async () => {
    const res = await fetch(process.env.REACT_APP_API_URL + "/profiles", {
      credentials: 'include' as RequestCredentials
    });
    let data = await res.json();
    console.log(data);
    try {
      if (data.message == "Unauthorized") {
        data = null
      }
    } catch (error) {
    }
    return data;
  };

  // is props a good way to do this?
  const page: string = "profiles";

  return (
    <Container maxWidth="xl">
      {/* subheader with filters */}
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        alignContent="stretch"
        className={classes.subHeader}
        spacing={2}
      >
        <Grid item>
          <Typography variant="h5">
            <b>Profiles</b>
          </Typography>
        </Grid>

        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            className={classes.filtersHeader}
          >
            {/* filter by search profiles; how do we search all of the variables? */}
            <Grid item>
              <TextField
                label="Search Profiles"
                variant="outlined"
                size="small"
                color="secondary"
                value={search}
                className={classes.searchBar}
                onChange={(event) => searchChange(event)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* filter menu - by position */}
            <Grid item>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <FormControl
                    size="small"
                    variant="outlined"
                    className={classes.filterMenu}
                  >
                    <InputLabel id="position-filter-label">Position</InputLabel>
                    <Select
                      labelId="position-filter-label"
                      id="position-filter"
                      value={position}
                      onChange={(event) => positionChange(event)}
                      label="Position"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {/* dynamically generated menu items from data*/}
                      {getUniquePositions().map((position, index) => (
                        <MenuItem value={position} key={index}>{position}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* filter menu - by skill
                <Grid item>
                  <FormControl
                    size="small"
                    variant="outlined"
                    className={classes.filterMenu}
                  >
                    <InputLabel id="skills-filter-label">Skills</InputLabel>
                    <Select
                      labelId="skills-filter-label"
                      id="skills-filter"
                      value={skills}
                      onChange={(event) => skillChange(event)}
                      label="Skills"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>

                      {getUniqueSkills().map((skills) => (
                        <MenuItem value={skills}>{skills}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid> */}

                {/* filter menu - by location */}
                <Grid item>
                  <FormControl
                    size="small"
                    variant="outlined"
                    className={classes.filterMenu}
                  >
                    <InputLabel id="location-filter-label">Location</InputLabel>
                    <Select
                      labelId="location-filter-label"
                      id="location-filter"
                      value={location}
                      onChange={(event) => locationChange(event)}
                      label="Location"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {/* dynamically generated menu items from data*/}
                      {getUniqueLocations().map((location, index) => (
                        <MenuItem value={location} key={index} >{location}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.addButton}
                    component={RouterLink}
                    to={"/addprofile"}
                  >
                    Add Profile
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* grid of profiles */}
      <Grid className={classes.cardGrid}>
        {loading === true ? (
          <Typography>Loading Profiles...</Typography>
        ) : (
          <div>
            {filteredData ? (
              <CardDisplay listData={filteredData} page={page} />
            ) : (
              //Display Loading Message
              <Typography></Typography>
            )}
          </div>
        )}
      </Grid>
    </Container>
  );
}

export default Profiles;

// Header – Title, search profiles (real time filter?) dropdown filters (position, skills, location), Add Profile Button
// Grid – of repeating ProfileCard components. Does the profile card need to a component?
