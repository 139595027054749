import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@material-ui/core';
import '@fontsource/roboto';
import "@fontsource/source-sans-pro";
import CssBaseline from "@material-ui/core/CssBaseline";

const theme = createTheme({
  palette: {
    primary: { main: '#1c242c', contrastText: '#ffffff' },
    secondary: { main: '#4ca9b2', contrastText: '#ffffff' },
    error: { main: '#f44336', contrastText: '#ffffff' },
    warning: { main: '#ff9800', contrastText: '#ffffff' },
    info: { main: '#2196f3', contrastText: '#ffffff' },
    success: { main: '#4caf50', contrastText: '#ffffff' },
    background: {
      default: "#F9FAFB"
    }
  },
  typography: {
    fontSize: 14,
    fontFamily: ["Source Sans Pro", "Roboto", "Helvetica", "Arial", "sans-serif"].join(','),
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
