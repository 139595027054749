import React from "react"
import Header from "../components/Header";
import Profiles from "./Profiles";
import Campaigns from "./Campaigns"
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function Main(): JSX.Element {
	return (
		<Switch>
			<Route path="/" exact>
				<Header page={'profiles'} />
				<Profiles />
			</Route>
			<Route path="/campaigns" exact>
				<Header page={'campaigns'} />
				<Campaigns />
			</Route>
			<Route path="/*" exact>
				<div>Oops that page doesn't exist</div>
				<Link to={"/"}>Go to home</Link>
			</Route>
		</Switch>
	)
}

export default Main;

// Header Component
// Route – to switch between subpages? Is there a better way to do this? state?
// Profiles as default view? Would there be a usecase where you navigate back to this page and want a different subpage displayed?