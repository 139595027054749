import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import NotificationsIcon from "@material-ui/icons/Notifications";
import EditIcon from "@material-ui/icons/Edit";
import {
  Link as RouterLink,
  Route,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import GetAppIcon from "@material-ui/icons/GetApp";
import ShareIcon from "@material-ui/icons/Share";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import {
  Dialog,
  DialogActions,
  TextField,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Grid,
} from "@material-ui/core";
interface Campaigns {
  campaign: {
    id: number;
    uuid: string;
    campaign_name: string;
    details: string;
    profiles: [
      {
        id: string;
        uuid: string;
        name: string;
        position: string;
        location: string;
      }
    ];
  };
}

function HeaderCampaign(): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [copied, setCopied] = useState("");
  const [campaign, setCampaign] = React.useState<Campaigns["campaign"]>();
  const [edit, editProfile] = React.useState<string>("");
  const [emailRecepient, setEmailRecepient] = React.useState<string>("");
  const [emailMessage, setEmailMessage] = React.useState<string>("");
  const [subjectLine, setSubjectLine] = React.useState<string>("");
  const [sender, setSender] = React.useState<string>("Squad CV");

  let urlParams: any = useParams();
  const campaignID = urlParams.uuid
  console.log("HeaderCampaign", campaignID)
  const shareLink = window.location.origin + "/share/campaign/" + campaignID

  const history = useHistory();

  //getting profile data
  useEffect(() => {
    const getCampaign = async () => {
      const thiscampaign = await fetchCampaign();
      setSubjectLine("Nodero have shared resumes for " + thiscampaign.campaign_name + " with you")
      setCampaign(thiscampaign);
    };
    getCampaign();
    setLoading(false);
  }, []);

  //fetching location state id in order to return profile
  const fetchCampaign = async (): Promise<any> => {
    const fetchURL = process.env.REACT_APP_API_URL + "/campaigns/" + campaignID;
    const res = await fetch(fetchURL);
    const data = await res.json();
    console.log("campaignheader id = " + campaignID);
    console.log('campaign', data)
    return data;
  };

  const useStyles = makeStyles({
    floatRight: {
      marginBottom: ".5vh",
      marginLeft: "auto",
      textTransform: "none",
    },
    Button: { textTransform: "none", marginBottom: ".5vh" },
    appBar: {
      height: "56px",
      display: "flex",
      alignContent: "center",
    },
    shareModal: {},
    shareAvatar: { width: "35px", height: "35px", background: "#4CA9B2" },
    shareTitle: { marginLeft: "8px" },
    shareTitleGroup: { marginTop: '5px' },
    copyLinkModal: { marginTop: '50%' },
    emailRecipient: {
      backgroundColor: "#EDEEF0",
      borderRadius: "4px",
      marginTop: "10px",
      color: "#4CA9B2",
      "& label.Mui-focused": {
        color: "#4CA9B2",
      },
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#4CA9B2",
      },
      "& .MuiFilledInput-root": {
        background: "#EDEEF0",
      },
    },
    inputFieldList: {
      backgroundColor: "#EDEEF0",
      padding: "0",
      "& li": {
        listStyle: "none",
        padding: "10px",
      },
    },
    linkButtonGroup: {},
    dialogActions: {
      marginRight: "15px",
      marginBottom: "12px",
    },
    copyButton: {},
  });
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCopied("");
    setEmailRecepient("");
    setEmailMessage("");
  };

  const handleShare = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: 'include' as RequestCredentials,
      body: JSON.stringify({
        toAddress: emailRecepient,
        message: emailMessage.replaceAll("\n", "<br>"),
        subject: subjectLine,
        sender: sender
      }),
    };
    fetch(process.env.REACT_APP_API_URL + "/campaigns/" + campaignID + "/share", requestOptions);
    setOpen(false);
  };

  async function copy() {
    await navigator.clipboard.writeText(shareLink);
    setCopied("Copied");
  }

  return (
    <AppBar className={classes.appBar} position="fixed" color="inherit">
      <Toolbar>
        <Button
          className={classes.Button}
          onClick={history.goBack}
          disableRipple
        >
          <KeyboardBackspaceIcon color="secondary" />
          <Typography style={{ marginLeft: "5px" }}></Typography>
        </Button>

        <Button
          disableRipple
          className={classes.floatRight}
          component={RouterLink}
          to={{
            pathname: `/editcampaign/${campaignID}`,
          }}
        >
          <EditIcon color="secondary" />
          <Typography style={{ marginLeft: "5px" }}>Edit</Typography>
        </Button>

        <Button disableRipple
          className={classes.Button}>
          <GetAppIcon color="secondary" />
          <Typography style={{ marginLeft: "5px" }}>Download</Typography>
        </Button>

        <Button
          disableRipple
          className={classes.Button}
          onClick={handleClickOpen}
        >
          <ShareIcon color="secondary" />
          <Typography style={{ marginLeft: "5px" }}>Share</Typography>
        </Button>

        <Dialog
          className={classes.shareModal}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>
            <Grid
              className={classes.shareTitleGroup}
              container
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Avatar className={classes.shareAvatar} color="primary">
                <PersonAddIcon />
              </Avatar>
              <Typography className={classes.shareTitle}>
                Share with people and groups
              </Typography>
            </Grid>
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              Share this resume by copying the link below or sending an email
            </DialogContentText>

            <TextField
              onKeyPress={(e) => e.key === "Enter" && handleShare()}
              className={classes.emailRecipient}
              value={emailRecepient}
              onChange={(e) => setEmailRecepient(e.target.value)}
              color="primary"
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              fullWidth
              size="small"
              variant="filled"
            />
          </DialogContent>

          <DialogContent>
            <DialogContentText>
              Customise the email
            </DialogContentText>
            <TextField
              className={classes.emailRecipient}
              value={sender}
              onChange={(e) => setSender(e.target.value)}
              color="primary"
              margin="dense"
              multiline
              id="subline"
              label="Sender"
              type="text"
              fullWidth
              size="small"
              variant="filled"
            />
          </DialogContent>
          <DialogContent>
            <TextField
              className={classes.emailRecipient}
              value={subjectLine}
              onChange={(e) => setSubjectLine(e.target.value)}
              color="primary"
              margin="dense"
              multiline
              id="subline"
              label="Subject Line"
              type="text"
              fullWidth
              size="small"
              variant="filled"
            />
          </DialogContent>
          <DialogContent>
            <TextField
              className={classes.emailRecipient}
              value={emailMessage}
              onChange={(e) => setEmailMessage(e.target.value)}
              color="primary"
              fullWidth
              multiline
              id="message"
            />
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button className={classes.Button} onClick={handleClose}>
              Cancel
            </Button>

            <Button
              className={classes.Button}
              variant="outlined"
              color="secondary"
              onClick={copy}
            >
              {!copied ? "Copy Link" : "Copied!"}
            </Button>

            <Button
              className={classes.Button}
              type="submit"
              onClick={handleShare}
              variant="contained"
              color="secondary"
            >
              Share
            </Button>
          </DialogActions>
        </Dialog>
      </Toolbar>
    </AppBar>
  );
}

export default HeaderCampaign;

// this header component is used on the various pages for edit/view/add of a specific entity
