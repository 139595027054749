import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';

// this is all static until we set up auth functionality with the backend

function Login(): JSX.Element {
  const history = useHistory();
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const submitButtonPressed = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: 'include' as RequestCredentials,
      body: JSON.stringify({
        email: email, password: password
      })
    };
    const res = await fetch(process.env.REACT_APP_API_URL + "/authentication/log-in", requestOptions)
    let data
    try {
      data = await res.json();
      let message = data.message
    } catch {
      data = await res.text;
      history.push('/')
    }
    console.log(data)
  }
  return (
    <Container component="main" maxWidth="xs" style={{ marginTop: '20px' }}>
      <CssBaseline />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar>
          <LockOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5" style={{ marginTop: '10px' }}>
          Sign in
        </Typography>
      </div>
      <TextField
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        color="secondary"
      />

      <TextField
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        color="secondary"
      />

      <FormControlLabel control={<Checkbox value="remember" color="secondary" />}
        label="Remember me"
      />

      <Button onClick={submitButtonPressed} type="submit" fullWidth variant="contained" color="secondary">
        Sign In
      </Button>

      <Grid container style={{ marginTop: '10px' }}>
        <Grid item xs>
          <Link href="#" variant="body2">
            Forgot password?
          </Link>
        </Grid>
        <Grid item>
          <Link href="/" variant="body2">
            Don't have an account? Sign Up
          </Link>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Login;


// needs a way for a company to change thier login details