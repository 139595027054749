import React, { useEffect } from "react"
import Header from "../components/Header";
import { useHistory, withRouter } from "react-router-dom";

// This functions calls the API to check if it has proper
// authentication. If not, redirect to /login
function Test(): JSX.Element {
    const history = useHistory();
    useEffect(() => {
        // this will retrieve database items on load
        const doTest = async () => {
            const testData = await test();
        };
        doTest();
    }, []);

    const test = async () => {
        const res = await fetch(process.env.REACT_APP_API_URL + "/authentication/test", {
            credentials: 'include' as RequestCredentials
        });
        console.log('LOCATION', window.location.href)
        let sharelink = false
        try {
            if (window.location.href.split('/')[3] == "share") {
                console.log("SHARE LINK SHARE LINK")
                sharelink = true
            }
        } catch (error) {
        }
        let data
        try {
            data = await res.text();
        } catch (error) {
            history.push("/login")
        }
        console.log("FROM TEST", data);
        if (data != "success" && !sharelink) {
            history.push("/login")
        }
        return data;
    };
    return (
        <>
        </>
    )
}

export default withRouter(Test);