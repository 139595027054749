import React from "react";
import HeaderProfile from "../../components/HeaderProfile";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import { Button, Container, Grid } from "@material-ui/core";
import { Avatar, Typography } from "@material-ui/core";
import {
  Link as RouterLink,
  Route,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { TextField } from "@material-ui/core";
import HeaderSimple from "../../components/HeaderSimple";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  TextareaAutosize,
} from "@material-ui/core";
import ProfileListCardDisplay from "../../components/ProfileListCardDisplay";
import Form from "@rjsf/material-ui";

interface LocationState {
  profileID: string;
}

const uiSchema: any = {
  summary: {
    "ui:widget": "textarea"
  }
}

const schema: any = {
  type: "object",
  properties: {
    basics: {
      title: "Basics",
      type: "object",
      properties: {
        name: {
          title: "Name",
          type: "string"
        },
        label: {
          title: "Label",
          type: "string"
        },
        summary: {
          title: "About",
          type: "string"
        },
        location: {
          title: "Location",
          type: "object",
          properties: {
            city: {
              title: "City",
              type: "string"
            },
            region: {
              title: "Region",
              type: "string"
            },
            countryCode: {
              title: "Country Code",
              type: "string"
            }
          }
        }
      }
    },
    work: {
      title: "Work Experience",
      type: "array",
      items: {
        type: "object",
        properties: {
          name: {
            title: "Company",
            type: "string"
          },
          position: {
            title: "Position",
            type: "string"
          },
          startDate: {
            title: "Start Date",
            type: "string"
          },
          endDate: {
            title: "End Date",
            type: "string"
          },
          summary: {
            title: "Summary",
            type: "string"
          }
        }
      }
    },
    education: {
      title: "Education",
      type: "array",
      items: {
        type: "object",
        properties: {
          institution: {
            title: "Institution",
            type: "string"
          },
          area: {
            title: "Course Type",
            type: "string"
          },
          startDate: {
            title: "Start Date",
            type: "string"
          },
          endDate: {
            title: "End Date",
            type: "string"
          }
        }
      }
    },
    skills: {
      title: "Skills",
      type: "array",
      items: {
        type: "object",
        properties: {
          name: {
            title: "Name",
            type: "string"
          }
        }
      }
    }
  }
}
interface JSON_Resume {
  json_resume: {
    basics: {
      name: any;
      label: any;
      summary: any;
      location: {
        city: any;
        region: any;
        countryCode: any;
      }
    },
    work: [{
      name: any;
      position: any;
      url: any;
      startDate: any;
      endDate: any;
      summary: any;
      highlights: [any]
    }],
    education: [{
      institution: any;
      area: any;
      startDate: any;
      endDate: any;
    }],
    skills: [{
      name: any;
      level: any;
      keywords: [any];
    }],
    references: [{
      name: any;
      reference: any;
    }]
  }
}
interface Profiles {
  profiles: {
    id: number;
    name: string;
    position: string;
    skills: [{
      name: any;
      level: any;
      keywords: [any];
    }];
    location: string;
    campaigns: [
      {
        id: number;
        campaign_name: string;
        created_at: string;
        startDate: string;
        endDate: string;
      }
    ];
  };
}
interface ProfileCardData {
  profiles: {
    id: number;
    uuid: string;
    parentuuid: string;
    name: string;
    position: string;
    skills: [{
      name: any;
      level: any;
      keywords: [any];
    }];
    location: string;
    campaign: string | null;
  }[];
  page: string;
  onCheckChange: Function;
  checked: any;
}
function EditProfile(): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [profile, setProfile] = React.useState<JSON_Resume["json_resume"]>();
  const [children, setChildren] =
    React.useState<ProfileCardData["profiles"]>();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [master, setMaster]: [null | JSON_Resume, React.Dispatch<React.SetStateAction<JSON_Resume>> | React.Dispatch<React.SetStateAction<null>>] = React.useState(null);

  let urlParams: any = useParams();
  const profileID = urlParams.uuid
  console.log("EditProfile", profileID)

  const history = useHistory();

  const useStyles = makeStyles({
    shareTitle: { marginLeft: "8px" },
    shareTitleGroup: { marginTop: '5px' },
    title: {
      marginBottom: "32px",
    },
    campaignContainer: {
      marginTop: "88px",
      display: "flex",
      marginBottom: "10px"
    },
    errorMessage: { color: "red" },
    profileAbout: {
      backgroundColor: "#EDEEF0",
      borderRadius: "4px",
      width: "488px",
      marginTop: "17px",
      color: "#4CA9B2",
      "& label.Mui-focused": {
        color: "#4CA9B2",
      },
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#4CA9B2",
      },
      "& .MuiFilledInput-root": {
        background: "#EDEEF0",
      },
    },
    profileName: {
      backgroundColor: "#EDEEF0",
      borderRadius: "4px",
      width: "488px",
      marginTop: "17px",
      color: "#4CA9B2",
      "& label.Mui-focused": {
        color: "#4CA9B2",
      },
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#4CA9B2",
      },
      "& .MuiFilledInput-root": {
        background: "#EDEEF0",
      },
    },
    inputFieldList: {
      backgroundColor: "#EDEEF0",
      padding: "0",
      "& li": {
        listStyle: "none",
        padding: "10px",
      },
    },
    saveButton: {
      marginLeft: "8px",
      marginTop: "17px",
      width: "80px",
      height: "32px",
      textTransform: "none",
    },
    deleteButton: {
      marginLeft: "8px",
      marginTop: "17px",
      width: "80px",
      height: "32px",
      textTransform: "none",
      backgroundColor: "#d32f2f",
      color: "white"
    },
    cancelButton: {
      marginTop: "17px",
      width: "80px",
      height: "32px",
      textTransform: "none",
    },
    revertButton: {
      marginTop: "17px",
      width: "80px",
      height: "32px",
      textTransform: "none",
      backgroundColor: "#ed6c02",
      color: "white"
    },
    buttonGroup: {
      marginLeft: "0%",
    }, dialogActions: {
      marginRight: "15px",
      marginBottom: "12px",
    },
  });
  const classes = useStyles();

  //enabled this for now
  const saveButtonPressed = () => {
    if (profile?.basics.name && profile.basics.label && profile.basics.summary && profile.basics.location) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: 'include' as RequestCredentials,
        body: JSON.stringify({
          json_resume: {
            ...profile
          },
        }),
      };
      console.log("Request options", requestOptions)
      fetch(process.env.REACT_APP_API_URL + "/profiles/" + profileID, requestOptions);
      history.goBack();
    } else {
      setErrorMessage("*Please fill in the required fields*");
    }
  };
  //enabled this for now
  const deleteButtonPressed = () => {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      credentials: 'include' as RequestCredentials,
    };
    console.log("Request options", requestOptions)
    fetch(process.env.REACT_APP_API_URL + "/profiles/" + profileID, requestOptions);
    history.go(-2);
  }
  const toTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }
  const revertButtonPressed = () => {
    console.log(master)
    if (master !== null) {
      setProfile(master)
    }
    toTop()
  }
  const overwriteButtonPressed = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheckChange = (event: any) => {
    console.log("CHILDREN1", children)
    const id = event.currentTarget.value
    const checked = event.currentTarget.checked
    console.log(id, checked)
    const child: any = children?.find(x => x.uuid == id)
    console.log("CHILD CHILD", child)
    console.log("CHILDREN2", children)
    setProfile(child.json_resume)
    setOpen(false);
    // let temp: any = { ...checkedProfiles }
    // temp[id] = checked
    // console.log(temp)
    // setCheckedProfiles(temp)
  }

  // on page load the profile id should be used to fetch the profile details
  // there may be a better way to do this
  useEffect(() => {
    toTop()
    const getProfile = async () => {
      const profileData = await fetchProfile();
      setProfile(profileData);
    };
    getProfile();

    setLoading(false);
  }, []);

  //fetch profile data for specific id
  const fetchProfile = async () => {
    const fetchURL = process.env.REACT_APP_API_URL + "/profiles/" + profileID
    const res = await fetch(fetchURL, {
      credentials: 'include' as RequestCredentials,
    });
    const data = await res.json();
    console.log("editpage profileid =" + profileID);
    console.log(data);
    if (data.parent !== null) {
      setMaster(data.parent.json_resume)
    } else {
      const childrenURL = process.env.REACT_APP_API_URL + "/profiles/" + profileID + '/children'
      const res = await fetch(childrenURL, {
        credentials: 'include' as RequestCredentials,
      });
      const data = await res.json();
      const children = data
      setChildren(children)
      console.log("CHILDREN", data)
    }
    return data.json_resume;
  };
  return (
    <Container className={classes.campaignContainer}>
      <Grid item>
        <HeaderSimple />
        <h2 className={classes.title}>Edit {profile?.basics.name} Profile</h2>

        <Form
          schema={schema}
          formData={profile}
          uiSchema={uiSchema}
          onChange={({ formData }) => { console.log(formData); setProfile(formData) }}
        ><></></Form>
        <Typography className={classes.errorMessage}>{errorMessage}</Typography>

        <div className={classes.buttonGroup}>
          <Button
            onClick={history.goBack}
            className={classes.cancelButton}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={saveButtonPressed}
            variant="contained"
            color="secondary"
            className={classes.saveButton}
          >
            Save
          </Button>

        </div>
        <div className={classes.buttonGroup}>
          {master === null ? (
            <Button
              onClick={overwriteButtonPressed}
              className={classes.cancelButton}
              variant="contained"
            >
              Overwrite
            </Button>
          ) : (
            <Button
              onClick={revertButtonPressed}
              className={classes.cancelButton}
              variant="contained"
            >
              Revert
            </Button>)}
          <Button
            onClick={deleteButtonPressed}
            className={classes.deleteButton}
            variant="contained"
          >
            Delete
          </Button>
        </div>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography className={classes.shareTitle}>
            Choose the profile you wish to overwrite with.
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Grid>
            {loading === true ? (
              <Typography>Loading Profiles...</Typography>
            ) : (
              <div>
                {children ? (
                  <ProfileListCardDisplay listData={children.map((r: any) => {
                    return {
                      id: r.id,
                      uuid: r.uuid,
                      name: r.json_resume.basics.name,
                      position: r.json_resume.basics.label,
                      //jsonresume skills could be changed from 'skills.name' > 'skills.keywords' as these are shorter and more descriptive of potential work we want
                      skills: r.json_resume.skills,
                      location: r.json_resume.basics.location.city,
                      campaign: null
                    };
                  })} page={"campaign"} onCheckChange={handleCheckChange} />
                ) : (
                  <Typography>No Profiles Found</Typography>
                )}
              </div>
            )}
          </Grid>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          {/* <Button className={classes.Button} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className={classes.Button}
            type="submit"
            onClick={handleShare}
            variant="contained"
            color="secondary"
          >
            Share
          </Button> */}
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default EditProfile;

// Header – Back to profiles button*, edit, download, share
// JSON resume, needs restyle? What does JSON R offer already?
// *This button might need to do different things depending on where to profile was accessed from. Could be accessed from Profiles Subpage OR Campaign
