import React from "react";
import HeaderProfile from "../../components/HeaderProfile";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import { Button, Container, Grid } from "@material-ui/core";
import { Avatar, Typography } from "@material-ui/core";
import { Link as RouterLink, Route, useHistory } from "react-router-dom";
import { TextField } from "@material-ui/core";
import { setegid } from "process";
import HeaderSimple from "../../components/HeaderSimple";
import Form from "@rjsf/material-ui";

const uiSchema: any = {
  summary: {
    "ui:widget": "textarea"
  }
}

const schema: any = {
  type: "object",
  properties: {
    basics: {
      title: "Basics",
      type: "object",
      properties: {
        name: {
          title: "Name",
          type: "string"
        },
        label: {
          title: "Label",
          type: "string"
        },
        summary: {
          title: "About",
          type: "string"
        },
        location: {
          title: "Location",
          type: "object",
          properties: {
            city: {
              title: "City",
              type: "string"
            },
            region: {
              title: "Region",
              type: "string"
            },
            countryCode: {
              title: "Country Code",
              type: "string"
            }
          }
        }
      }
    },
    work: {
      title: "Work Experience",
      type: "array",
      items: {
        type: "object",
        properties: {
          name: {
            title: "Company",
            type: "string"
          },
          position: {
            title: "Position",
            type: "string"
          },
          startDate: {
            title: "Start Date",
            type: "string"
          },
          endDate: {
            title: "End Date",
            type: "string"
          },
          summary: {
            title: "Summary",
            type: "string"
          }
        }
      }
    },
    education: {
      title: "Education",
      type: "array",
      items: {
        type: "object",
        properties: {
          institution: {
            title: "Institution",
            type: "string"
          },
          area: {
            title: "Course Type",
            type: "string"
          },
          startDate: {
            title: "Start Date",
            type: "string"
          },
          endDate: {
            title: "End Date",
            type: "string"
          }
        }
      }
    },
    skills: {
      title: "Skills",
      type: "array",
      items: {
        type: "object",
        properties: {
          name: {
            title: "Name",
            type: "string"
          }
        }
      }
    }
  }
}
interface JSON_Resume {
  json_resume: {
    basics: {
      name: any;
      label: any;
      summary: any;
      location: {
        city: any;
        region: any;
        countryCode: any;
      }
    },
    work: [{
      name: any;
      position: any;
      url: any;
      startDate: any;
      endDate: any;
      summary: any;
      highlights: [any]
    }],
    education: [{
      institution: any;
      area: any;
      startDate: any;
      endDate: any;
    }],
    skills: [{
      name: any;
      level: any;
      keywords: [any];
    }],
    references: [{
      name: any;
      reference: any;
    }]
  }
}

function AddProfile(): JSX.Element {
  const [loading, setLoading] = useState(true);

  const [profile, setProfile] = React.useState<JSON_Resume["json_resume"]>();
  //Profile Basics : name, position, location, about
  const [profileName, setProfileName] = React.useState("");
  const [profilePosition, setProfilePosition] = React.useState("");
  const [profileLocation, setProfileLocation] = React.useState("");
  const [profileAbout, setProfileAbout] = React.useState("");
  //Experience : name, position, startDate, endDate, summary, highlights?
  const [experienceName, setExperienceName] = React.useState("");
  const [experiencePosition, setExperiencePosition] = React.useState("");
  const [experienceStartDate, setExperienceStartDate] = React.useState("");
  const [experienceEndDate, setExperienceEndDate] = React.useState("");
  const [experienceSummary, setExperienceSummary] = React.useState("");
  //Education : institution,area,startDate,endDate,
  const [educationInstitution, setEducationInstitution] = React.useState("");
  const [educationArea, setEducationArea] = React.useState("");
  const [educationStartDate, setEducationStartDate] = React.useState("");
  const [educationEndDate, setEducationEndDate] = React.useState("");
  //Skills : name, keywords[]
  const [skillsName, setSkillsName] = React.useState("");
  const [skillsKeywords, setSkillsKeywords] = React.useState("");

  //Error
  const [errorMessage, setErrorMessage] = React.useState("");

  const history = useHistory();

  useEffect(() => {
    setLoading(false);
  }, []);

  const useStyles = makeStyles({
    title: {
      marginBottom: "12px",
    },
    campaignContainer: {
      marginTop: "88px",
      display: "flex",
    },
    errorMessage: { color: "red" },
    profileAbout: {
      backgroundColor: "#EDEEF0",
      borderRadius: "4px",
      width: "488px",
      marginTop: "12px",
      color: "#4CA9B2",
      "& label.Mui-focused": {
        color: "#4CA9B2",
      },
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#4CA9B2",
      },
      "& .MuiFilledInput-root": {
        background: "#EDEEF0",
      },
    },
    profileName: {
      backgroundColor: "#EDEEF0",
      borderRadius: "4px",
      width: "488px",
      marginTop: "12px",
      color: "#4CA9B2",
      "& label.Mui-focused": {
        color: "#4CA9B2",
      },
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#4CA9B2",
      },
      "& .MuiFilledInput-root": {
        background: "#EDEEF0",
      },
    },
    inputFieldList: {
      backgroundColor: "#EDEEF0",
      padding: "0",
      "& li": {
        listStyle: "none",
        padding: "10px",
      },
    },
    saveButton: {
      marginLeft: "8px",
      marginTop: "12px",
      width: "80px",
      height: "32px",
      textTransform: "none",
    },
    cancelButton: {
      marginTop: "12px",
      width: "80px",
      height: "32px",
      textTransform: "none",
    },
    buttonGroup: {
      marginLeft: "0%",
      marginBottom: "5%",
    },
  });
  const classes = useStyles();

  // Saving inputs as a profile **Validation required
  const saveButtonPressed = () => {
    if (profile?.basics.name && profile.basics.label && profile.basics.summary && profile.basics.location) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: 'include' as RequestCredentials,
        body: JSON.stringify({
          json_resume: {
            ...profile
          },
        }),
      };
      fetch(process.env.REACT_APP_API_URL + "/profiles", requestOptions);
      history.goBack();
    } else {
      setErrorMessage("*Please fill in the required fields*");
    }
  };

  const page = "profiles";

  return (
    <Container maxWidth="sm" className={classes.campaignContainer}>
      <Grid item>
        <HeaderSimple />
        <h2 className={classes.title}>Add Profile</h2>

        <Form
          schema={schema}
          formData={profile}
          uiSchema={uiSchema}
          onChange={({ formData }) => { console.log(formData); setProfile(formData) }}
        ><></></Form>

        <Typography className={classes.errorMessage}>{errorMessage}</Typography>

        {/* <h3>Experience</h3>

        <TextField
          value={experienceName}
          onChange={(e) => setExperienceName(e.target.value)}
          name="profile_about"
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          size="medium"
          fullWidth
          variant="filled"
          label="Name"
          className={classes.profileAbout}
        ></TextField>

        <TextField
          value={experiencePosition}
          onChange={(e) => setExperiencePosition(e.target.value)}
          name="profile_about"
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          size="medium"
          fullWidth
          variant="filled"
          label="Position"
          className={classes.profileAbout}
        ></TextField> */}

        {/* <DesktopDatePicker
          label="Date desktop"
          inputFormat="MM/dd/yyyy"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        /> */}

        {/* <TextField
          value={experienceStartDate}
          onChange={(e) => setExperienceStartDate(e.target.value)}
          name="profile_about"
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          size="medium"
          fullWidth
          variant="filled"
          label="Start Date"
          placeholder="yyyy-mm-dd"
          className={classes.profileAbout}
          type="number"
        ></TextField>

        <TextField
          type='number'
          value={experienceEndDate}
          onChange={(e) => setExperienceEndDate(e.target.value)}
          name="profile_about"
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          size="medium"
          fullWidth
          variant="filled"
          label="End Date"
          placeholder="yyyy-mm-dd"
          className={classes.profileAbout}
        ></TextField>

        <TextField
          value={experienceSummary}
          onChange={(e) => setExperienceSummary(e.target.value)}
          name="profile_about"
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          size="medium"
          fullWidth
          variant="filled"
          label="Summary"
          className={classes.profileAbout}
        ></TextField>

        <h3>Education</h3>

        <TextField
          value={educationArea}
          onChange={(e) => setEducationArea(e.target.value)}
          name="profile_about"
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          size="medium"
          fullWidth
          variant="filled"
          label="Area"
          className={classes.profileAbout}
        ></TextField>

        <TextField
          value={educationInstitution}
          onChange={(e) => setEducationInstitution(e.target.value)}
          name="profile_about"
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          size="medium"
          fullWidth
          variant="filled"
          label="Institution"
          className={classes.profileAbout}
        ></TextField>

        <TextField
          value={educationStartDate}
          onChange={(e) => setEducationStartDate(e.target.value)}
          name="profile_about"
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          size="medium"
          fullWidth
          variant="filled"
          label="Start Date"
          placeholder="yyyy-mm-dd"
          className={classes.profileAbout}
        ></TextField>

        <TextField
          value={educationEndDate}
          onChange={(e) => setEducationEndDate(e.target.value)}
          name="profile_about"
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          size="medium"
          fullWidth
          variant="filled"
          label="End Date"
          placeholder="yyyy-mm-dd"
          className={classes.profileAbout}
        ></TextField>

        <h3>Skills</h3>

        <TextField
          value={skillsName}
          onChange={(e) => setSkillsName(e.target.value)}
          name="profile_about"
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          size="medium"
          fullWidth
          variant="filled"
          label="Name"
          className={classes.profileAbout}
        ></TextField>

        <TextField
          value={skillsKeywords}
          onChange={(e) => setSkillsKeywords(e.target.value)}
          name="profile_about"
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          size="medium"
          fullWidth
          variant="filled"
          label="Keywords"
          className={classes.profileAbout}
        ></TextField> */}

        <div className={classes.buttonGroup}>
          <Button
            onClick={history.goBack}
            className={classes.cancelButton}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={saveButtonPressed}
            variant="contained"
            color="secondary"
            className={classes.saveButton}
          >
            Save
          </Button>
        </div>
      </Grid>
    </Container>
  );
}

export default AddProfile;

// Header – Back to profiles button*, edit, download, share
// JSON resume, needs restyle? What does JSON R offer already?
// *This button might need to do different things depending on where to profile was accessed from. Could be accessed from Profiles Subpage OR Campaign
